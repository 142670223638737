.default-button.default-button {
  background-color: #2e343a;
  color: #d8d8d8;
}

.default-button.default-button:hover {
  background-color: #101214;
}

.default-button.default-button:active {
  opacity: 0.8;
}