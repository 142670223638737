body {
  margin: 0;
  padding: 0;
  font-family: 'Mada',sans-serif;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important; 
}

.input-price:focus  {
  outline: none;
}

/** TIMELINE INIT **/

.vertical-timeline.vertical-timeline--two-columns:before {
  background-color: #000;
}

.vertical-timeline-element-date {
  font-weight: bold !important;
  color: #5B49E5;
}

/** TIMELINE END **/

/** INIT CUSTOM MAPBOX **/

.mapboxgl-ctrl-attrib-inner {
  display: none !important;
}

.mapboxgl-ctrl-bottom-right > .mapboxgl-ctrl-attrib.mapboxgl-compact:after {
  display: none !important;
}

/** END CUSTOM MAPBOX **/

/** INIT Timeline Readmore **/

.read-more-button {
  color: #5B49E5;
}

.read-more-button:hover {
  text-decoration: underline;
  cursor: pointer;
}

.display-text-group {
  display: inline;
  word-break: break-word;
}

/** END react-read-more **/


/** INIT Amplify Customs **/
.Button__button___vS7Mv {
  background-color: #5B49E5 !important;
}

.Anchor__a___1_Iz8 {
  color: #5B49E5 !important;
}

/** END Amplify Customs **/

/** INIT Beamer Config **/

.beamer_defaultBeamerSelector {
  display: none !important;
}

/** END Beamer Config **/


.pro-sidebar > .pro-sidebar-inner {
  color: white;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
  background-color: #2E343A;
}