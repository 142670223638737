.send-button.send-button {
  background-color: #2e343a;
  color: #d8d8d8;
}

.send-button.send-button:hover {
  background-color: #101214;
}

.download-button.download-button {
  background-color: #256d85;
  color: #d8d8d8;
}

.download-button.download-button:hover {
  background-color: #002b5b;
}

.send-button.send-button:active,
.download-button.download-button:active {
  opacity: 0.8;
}
